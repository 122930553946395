import Vue from 'vue'
import "./assets/theme/common.css";
import ElementUI from 'element-ui';
import App from './App.vue'
import store from './store'
import VueStorage from 'vue-ls'
import Fragment from 'vue-fragment'



import ReceptionShow from './page/receptionShow.vue';
import Reception from './page/reception.vue';
import Count from './page/count.vue';
import ReceptionList from './page/receptionList.vue';
import Login from './page/users/login.vue';
import Password from './page/users/password.vue';
import Member from './page/users/member.vue';
import Title from './components/title.vue';
import vueRouter from 'vue-router';



Vue.config.productionTip = false;
Vue.use(vueRouter);
Vue.use(ElementUI);
Vue.use(Fragment.Plugin)
Vue.use(VueStorage, {
  namespace: 'badge__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
})


new Vue({
  router: new vueRouter({
    routes: [{
      name: 'receptionShow',
      path: '/receptionShow',
      components: {
        default: ReceptionShow,
        title: Title
      },
      content: {
        title: '接待详情',
        desc: ''
      }
    },
    {
      name: 'login',
      path: '/login',
      components: {
        default: Login,
        title: Title
      },
      content: {
        title: '登录',
        desc: ''
      }
    },
    {
      name: 'password',
      path: '/password',
      components: {
        default: Password,
        title: Title
      },
      content: {
        title: '修改密码',
        desc: ''
      }
    },
    {
      name: 'member',
      path: '/member',
      components: {
        default: Member,
        title: Title
      },
      content: {
        title: '个人中心',
        desc: ''
      }
    },
    {
      name: 'reception',
      path: '/reception',
      components: {
        default: Reception,
        title: Title
      },
      content: {
        title: '销售意向卡',
        desc: ''
      }
    },
    {
      name: 'index',
      path: '/index',
      components: {
        default: ReceptionList,
        title: Title
      },
      content: {
        title: '接待列表',
        desc: ''
      }
    },
    {
      name: 'count',
      path: '/count',
      components: {
        default: Count,
        title: Title
      },
      content: {
        title: '统计分析',
        desc: ''
      }
    },
    { path: '/', component: ReceptionList },
  ]
  }),
  store,
  render: h => h(App),
}).$mount('#app')
