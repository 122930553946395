import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'



const prod = /^badge\./.test(location.hostname);
const apiHost = prod ? 'https://api.linkprofit.cn/': 'https://test.api.linkprofit.cn/';

//const apiHost = prod ? 'https://test.api.linkprofit.cn': 'http://192.168.33.50';

export function getApiHost() {
  return apiHost;
}

axios.interceptors.request.use(
  config => {
    config.baseURL = apiHost + '/index.php'
    config.withCredentials = true
    const token = Vue.ls.get('ACCESS_TOKEN')
    if (token) {
      config.headers['token'] = token
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
axios.interceptors.response.use(
  response => {
    if(response.data && response.data.status == 401) {
      location.href='#/login';
    }
    return response
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)


export function fetchGet (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}

export function fetchPost (url, params = {}, headers) {
    return new Promise((resolve, reject) => {
      axios.post(url, params, headers).then(res => {
        if (!res) {
          resolve({})
        } else {
          resolve(res.data)
        }
      }).catch(error => {
        reject(error)
      })
    })
  }

export function postForm (url, params = {}) {
    if (params.sn === true) {
      const sn = Vue.ls.get('SN') || '';
      params.sn = sn;
    }

    return new Promise((resolve, reject) => {
      const headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        };
      const p = {};
      for(var i in params) {
         var value = params[i];
         if(value !== null){
           p[i] = value;
         }
      }
      axios.post(url, p, {headers}).then(res => {
        if (!res) {
          resolve({})
        } else {
          resolve(res.data)
        }
      }).catch(error => {
        reject(error)
      })
    })
  }

export function login (data) {
  return fetchPost('/wifi/admin/login', data);
}


export function logout () {
  return Promise.resolve(true)
}



export default {
  fetchGet,
  fetchPost,
  postForm,
};
  
