<template>
  <div class="app">
    <router-view ></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {
    online() {
    },
    offline() {
      this.$message.error('当前网络不可用，请检查你的网络设置');
    }
  },
  created() {
    const el = document.body;
    if (el.addEventListener) {
      window.addEventListener("offline", this.offline, true);
      window.addEventListener("online", this.online, true);
    } else if (el.attachEvent) {
      window.attachEvent("onoffline", this.offline);
      window.attachEvent("ononline", this.online);
    } else {
      window.onoffline = this.offline;
      window.ononline = this.online;
    }
  }
}

</script>

<style lang="less">

html, body, .app, .bg-main, .bg-user {
  height: 100%;
  overflow: scroll;
}
.app {
  min-width: 320px;
  max-width: 750px;
}
.el-message {
  top: 40% !important;
  min-width: 60% !important;
  background: #4F4F4F;
  border-radius: 10px;

  .el-message__content {
    color: #fff;
    word-break: break-word;
    line-height: 1.3;
    font-size: 0.7rem;
  }
}


.bg-main {
  background: #F5F6F8 url('~@/assets/bg_main.png') top center no-repeat;
  background-size: 100% auto;
  background-attachment: fixed;
}

.bg-user {
  background: #fff url('~@/assets/bg_user.png') top center no-repeat;
  background-size: 100% auto;
  
  .box-title {
    font-weight: bold;
    height: 9.6rem;
    line-height: 9.6rem;
    color: #fff;
    text-align: center;
  }
}

.top-round {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.round {
  border-radius: 0.5rem;
}

.sec {
  margin: 0.5rem 0.8rem 0 0.8rem;
  background: #fff;
  border-radius: 0.5rem;
  padding: 0 0.8rem;
}

.box-form {
  .el-form-item {
    margin-bottom: 1.2rem;
  }

  .el-form-item__content {
      line-height: 1.5rem;
  }

  .el-form-item__label {
    font-size: 0.8rem;
    font-weight: 500;
    color: #000000;
    padding: 0;
    line-height: 1.3;

    &:before {
      display: none;
    }
  }

  .el-input {
    width: 100%;
    .el-input__inner {
      border: 0;
      border-bottom: 1px solid #EFEFEF;
      border-radius: 0;
      padding: 0;
      height: 1.5rem;
      line-height: 1.5rem;
    }
  }

  .el-icon-date {
    display: none;
  }
  .group-btn {
    padding-top: 0.2rem;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    .el-radio__input {
      display: none;
    }
    >label {
      text-align: center;
      margin-bottom: 0.3rem;
      background: #F5F5F5;
      border-color: #F5F5F5;
      margin: 0;
      font-weight: 400;

      &.col2 {
        width: 48%;
        margin-top: 0.5rem;
        margin-left: 0 !important;
      }
      &.col3 {
        width: 30%;
      }

      &.is-checked {
        background: #fff;
      }
    }
  }
}

.item-justify {
  li {
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EFEFEF;

    &:last-child {
      border: 0;
    }
  }
}
</style>
