<template>
  <div class="page-lg bg-user">
    <div class="box-title">明灯</div>
    <div class="box-lg top-round">
    <el-form :model="lgForm" :rules="rules"
     ref="lgForm" 
     label-position="top" 
     class="box-form"
     >
        <el-form-item label="用户名" prop="username">
            <el-input type="text" 
                v-model="lgForm.username" 
                auto-complete="off" 
                placeholder="请输入手机号或用户名"
            >
            </el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
            <el-input :type="pwdType"
                v-model="lgForm.password" 
                auto-complete="off" 
                placeholder="请输入登录密码"
            >
            <span slot="suffix" class="lg-label">
              <img v-if="pwdType=='password'" src="@/assets/eye_open.png" @click="pwdType='text'"/>
              <img v-else src="@/assets/eye_close.png" @click="pwdType='password'"/>
            </span>
            </el-input>
        </el-form-item>
        <!--
        <el-form-item label="验证码" prop="imgCode">
          <div class="box-code">
            <el-input type="text" 
                v-model="lgForm.imgCode" 
                auto-complete="off" 
                placeholder="请输入图中验证码"
            >
            </el-input>
          <img :src="codeImgUrl" @click="getCodePic"/>
          </div>
        </el-form-item>
        -->
        <el-form-item class="box-btn">
            <el-button class="btn-login" @click="handleSubmit" :loading="logining">登录</el-button>
        </el-form-item>
    </el-form>
  </div>    
</div>    
</template>

<script>
import Vue from 'vue'
import { getApiHost } from '@/api'

export default {
  data(){
    return {
        codeImgUrl: '',
        pwdType: 'text',
        logining: false,
        lgForm: {
            username: '',
            password: '',
            imgCode: '',
        },
        rules: {
            username: [{required: true, message: '请输入', trigger: 'blur'}],
            password: [{required: true, message: '请输入', trigger: 'blur'}],
            imgCode: [{required: true, message: '请输入', trigger: 'blur'}]
        },
        checked: true
    }
  },
  methods: {
    getCodePic() {
      this.random = parseInt(Math.random()*10000);
      this.imgCode = '';
      this.codeImgUrl = getApiHost() + '/index.php/wifi/admin/captchaH5?random=' + this.random;
    },
    handleSubmit(){
      //测试账号  17630417493
      //密码    123456
        this.$refs.lgForm.validate((valid) => {
            if(valid){
                this.lgForm.random = this.random;
                const lgInfo = this.checked ? this.lgForm : null;
                this.logining = true;
                this.$store.dispatch('Login', this.lgForm).then((res) => {
                  console.log(res);
                  this.logining = false;    
                  if (res.status === 1) {
                    Vue.ls.set('LOGIN_INFO', lgInfo);
                    this.$router.push({name: 'index'});
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              
            }
        })
    }
  },
  created() {
    const login = Vue.ls.get('LOGIN_INFO') || {};
    if (login) {
      this.lgForm = {
        username: login.username,
        password: login.password
      }
    }
    //this.getCodePic();
  }
  
}
</script>

<style lang="less">
.page-lg {
  position: relative;
  
  .box-title {
    font-size: 2rem;
  }

  .box-lg {
    padding: 1.5rem;
    background: #fff;
  }

  .lg-label img{
    width: 0.9rem;
    height: 0.9rem;
  }
  .box-btn {
    text-align: center;
    padding: 2.5rem 1rem 0.5rem 1rem;
  }
  .btn-login {
    height: 2.3rem;
    width: 100%;
    background: #377EEA;
    border-radius: 0.5rem;
    color: #fff;
  }

  .box-code {
    display: flex;
    div {
      flex: 1;
    }
    img {
      height: 1.5rem;
      margin-left: 0.5rem;
      vertical-align: bottom;
    }
  }
}

</style>
