<template>
  <div class="page-list bg-main">
    <div class="box-top">
      <div class="fixed">
      <div class="box-state">
        <span v-if="online" class="wifi" style="background: rgba(60,176,122,0.3);"><i style="background: #77E7AF"></i>在线</span>
        <span v-else class="wifi"><i style="background: #ED5A54"></i>离线</span>
        <span v-if="electricity" class="energy">
            <span><i :style="`height: ${electricity}%`"></i></span>{{electricity}}%</span>
      </div>
      <div class="box-tab">
        <p v-for="(item,idx) in tabs" :key="idx"
          :class="item.id==curTab ? 'sel' : ''"
           @click="onChangeTab(item.id)"
           > <i></i>{{item.title}} </p>
      </div>
      <div class="box-search" @click="showDrawer=true">
        <img src="@/assets/i_search.png" />
        <el-input v-model="ruleForm.name" readonly placeholder="请输入内容">
        </el-input>
        <span class="btn">筛选</span>
      </div>
      </div>
    </div>

    <div v-if="isEmpty" class="box-empty">
      <img src="@/assets/empty.png" />
    </div>
    <div v-else class="box-list">
      <div class="item" v-for="(item, index) in dataList" :key="index"
        @click="jumpTo(item)">
        <p class="name">{{item.name}} - {{item.phone}}</p>
        <p class="time">{{item.date}}</p>
        <p v-if="!item.voice" class="sound">{{item.is_record}}</p>
        <div>
          <strong style="right: 3.0rem;" v-if="item.recordMode == 1 || item.recordMode == 0 " >WIFI开启</strong>
          <strong style="right: 3.0rem;" v-if="item.recordMode == 2" >手动开启</strong>
        </div>
        <strong v-if="item.statue == '已完成'" class="green">{{item.statue}}</strong>
        <strong v-if="item.statue == '待补录'" class="yellow">{{item.statue}}</strong>
        <strong v-if="item.statue == '接待中'" class="blue">{{item.statue}}</strong>
      </div>

      <div v-if="showMore" style="text-align: center; padding: 1rem 0; color: #999" @click="onSearch()">点击加载更多 <i class="el-icon-arrow-down"></i></div>
    </div>
    <div class="box-fixed"><a @click="startReception">开始接待</a></div>


    <el-dialog
      class="pop-type"
      title="请选择业务类型"
      :visible.sync="showPop"
      width="80%"
      >
      <ul class="box-type">
        <li v-for="(item,idx) in types" :key="idx" @click="onChangeType(item)">
          <div class="name">{{item}}</div>
          <div class="intro">选择{{item}}</div>
        </li>
      </ul>
    </el-dialog>

    <el-drawer
      class="pop-search"
      title="筛选"
      direction="btt"
      size="80%"
      :visible.sync="showDrawer"
      >
      <el-form :model="ruleForm"
        status-icon ref="ruleForm"
        label-position="top" 
        class="box-form"
        >
        <el-form-item label="按日期">
          <div class="box-date">
            <el-date-picker
              @change="startChange"
              v-model="ruleForm.start_date"
              type="date"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              clear-icon
              placeholder="开始日期">
            </el-date-picker>
            <el-date-picker
              @change="endChange"
              v-model="ruleForm.end_date"
              type="date"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              clear-icon
              placeholder="结束日期">
            </el-date-picker>
          </div>
          <img class="ic" src="@/assets/i_date_d.png" />
        </el-form-item>
        <el-form-item label="客户手机号">
          <el-input type="text" v-model="ruleForm.phone" placeholder="请输入客户手机号"></el-input>
          <img class="ic" src="@/assets/i_search.png" />
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input type="text" v-model="ruleForm.name" placeholder="请输入客户姓名">
          </el-input>
          <img class="ic" src="@/assets/i_search.png" />
        </el-form-item>
        <el-form-item label="接待状态">
          <el-radio-group v-model="ruleForm.status" class="group-btn">
            <el-radio
              v-for="(item,idx) in states" :key="idx"
              class="col3"
              :label="idx+1" border>{{item}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="录音上传">
          <el-radio-group v-model="ruleForm.record_status" class="group-btn">
            <el-radio
              v-for="(item,idx) in sounds" :key="idx"
              class="col3"
              :label="idx+1" border>{{item}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="pop-btn">
          <el-button @click="ruleForm={}">重置</el-button>
          <el-button type="primary" @click="onSearch(1)">提交</el-button>
        </el-form-item>
      </el-form>
      </el-drawer>

    <NavBar tab="销售接待"></NavBar>
  </div>
</template>

<script>
import { postForm } from '@/api'
import NavBar from '@/components/navbar.vue';


export default {
  data() {
      return {
        ruleForm: {
        },
        curTab: 1,
        cardId: '',
        tabs: [
          {title: '当日接待', id: 1},
          {title: '历史接待', id: 2},
        ],
        types: ['展厅接待','DCC呼入','DCC呼出'],
        states: ['接待中','已完成','待补录'],
        sounds: ['未上传','已上传'],
        sn: '',
        showDrawer: false,
        showPop: false,
        isEmpty: false,
        pageNum: 1,
        pageSize: 20,
        dataList: [],
        showMore: false,
        online: false,
        electricity: 0,
        startOpts: {
          disabledDate: (time) => {
              return new Date(time) > new Date(this.end_date || '')
          }
        },
        endOpts: {
          disabledDate: (time) => {
              return new Date(time) < new Date(this.start_date || '')
          }
        }
      }
  },
  components:{
    NavBar,
  },
  methods : {

    startReception() {
      postForm('/wifi/record/getRecordStatus').then(res => {
        if (res.status === 1) {
          const {business, task_num, status} = res.data || {};
          if(status == 'start') {
            this.$router.push({path: '/reception', query: {type: business, task_num, status}});
          } else {
            this.showPop = true;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    jumpTo(item) {
      const {id, statue, business,taskNum} = item;
      if (statue === '待补录') {
        this.$router.push({path: '/reception', query: {id, type: business}});
        return;
      }
      if (statue === "接待中") {
        this.$router.push({path: '/reception', query: {task_num:taskNum, type: business, status:"start"}});
        return;
      }
      this.$router.push({path: '/receptionShow', query: {id}});
    },
    closeDrawer() {
      this.ruleForm = {};
      this.showDrawer = false;
    },
    onChangeType(item) {
      this.$router.push({path: '/reception', query: {id: this.cardId, type: item}});
    },
    onChangeTab(id) {
      this.$router.replace({path: '/index', query: {id}});
      this.onSearch(1);
    },
    getTypes() {
      postForm('/wifi/home/business').then(res => {
        if (res.status === 1) {
          this.types = (res.data || []).map(o => o.name);
        }
      });
    },
    getState() {
      postForm('/wifi/admin/syncOnlineStatus').then(res => {

        if (res.status === 1) {
          const {states, electricity} = res.data || {};
          this.online = states == 'on';
          this.electricity = electricity
        }
      });
    },
    setTimer() {
      if(this.timer == null) {
        this.timer = setInterval( () => {
          this.getState()
          console.log('开始定时...每过一分钟执行一次')
        }, 1000*60)
      }
    },
    endChange(v) {
      this.end_date = v;
    },
    startChange(v) {
      this.start_date = v;
    },
    onSearch(num) {
      const {id} = this.$router.currentRoute.query || {};
      id && (this.curTab = id);

      const pageNum = num || this.pageNum;
      const {pageSize, curTab, ruleForm} = this;
      postForm('/wifi/home/index', {
        ...ruleForm,
        today: curTab,
        pageSize,
        pageNum
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }

          if (pageNum === 1) {
            this.dataList = res.data;
          } else {
            this.dataList = [...this.dataList, ...res.data];
          }

          this.isEmpty = !this.dataList.length;
          this.pageNum = Number(res.pageNum) + 1;
          const total = Number(res.total);
          this.showMore = total > this.dataList.length;

          this.showDrawer = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
  },
  created() {
    this.getState();
    this.onSearch();
    this.getTypes();
    this.setTimer();
  }
    
}
</script>

<style lang="less">

.page-list {
.box-form {
  padding: 0.5rem 1rem;

  .el-form-item {
    margin-bottom: 1rem;
  }
  .el-form-item__label {
    font-weight: 400;
    line-height: 1.2;
  }

  .box-date {
    display: flex;

    .el-icon-date {
    }
  }
  .group-btn {
    justify-content: flex-start;
  }
}

.pop-type {
  .el-dialog {
    border-radius: 0.5rem;
  }
  .el-dialog__body {
  }

  .box-type {
    li {
      background-color: #F5F5F5;
      border-radius: 5px;
      margin-bottom: 0.5rem;
      padding: 0.5rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      /*
      background-position: 90% center;
      background-repeat: no-repeat;
      background-size: auto 2rem;

      &:nth-child(1){
        background-image: url('~@/assets/type_bussiness.png');
      }
      &:nth-child(2){
        background-image: url('~@/assets/type_in.png');
      }
      &:nth-child(3){
        background-image: url('~@/assets/type_out.png');
      }
      */

      .name {
        font-size: 0.8rem;
        font-weight: 500;
      }
      .intro {
        font-size: 0.6rem;
        font-weight: 400;
        opacity: 36%;
      }
    }
  }
}
.pop-search {
  .el-drawer {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .el-drawer__header {
    font-size: 1rem;
    color: #000;
  }

  .ic {
    position: absolute;
    right: 0;
    bottom: 0.4rem;
    width: 1rem;
    height: 1rem;
  }
  .pop-btn {
    border-top: 1px solid #EFEFEF;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;

    .el-form-item__content {
      position: relative;
      display: flex;
      line-height: 2rem;
      height: 2rem;

      button {
        flex: 1;
        border: 0;
        border-radius: 0;
        line-height: 2rem;
        padding: 0;
      }

    }
  }
}

.box-fixed {
  position: fixed;
  bottom: 4rem;
  left: 0;
  right: 0;
  text-align: center;
}
.box-fixed a{
  display: inline-block;
  padding: 0.4rem 2.5rem;
  color: #fff;
  border-radius: 20px;
  font-size: 0.8rem;
  background: linear-gradient(132deg, #377EEA 0%, rgba(55,126,234,0.76) 100%);
  box-shadow: 0px 4px 10px 0px rgba(55,126,234,0.2);
}

.box-top {
  height: 8rem;
  .fixed {
    z-index: 1000;
    background: url('~@/assets/bg_main.png') top center no-repeat;
    background-size: 100% auto;
    padding: 0.5rem 0.8rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .box-state {
    height: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    .wifi {
      background: rgba(0,0,0,0.15);
      border-radius: 8px;
      padding: 0.1rem 0.5rem;
      color: #F5F5F5;
      font-size: 0.6rem;
      display: flex;
      align-items: center;
      i {
        margin-right: 0.2rem;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
      }
    }
    .energy {
      display: flex;
      align-items: center;
      font-size: 0.6rem;
      span {
        width: 0.4rem;
        height: 0.7rem;
        display: inline-block;
        background-size: auto 100%;
        vertical-align: middle;
        position: relative;
        border: 1px solid #333;
        border-radius: 1px;
        margin-right: 0.2rem;
        &:before {
          content: ' ';
          position: absolute;
          top: -0.1rem;
          left: 50%;
          transform: translateX(-50%);
          width: 0.2rem;
          height: 0.1rem;
          background: #333;
        }

        i {
          position: absolute;
          bottom: 1px;
          left: 1px;
          right: 1px;
          display: block;
          background: #333;
          max-height: 0.5rem;
        }
      }
    }
  }
}
.box-tab {
  display: flex;
  justify-content: space-between;
  p {
    display: flex;
    align-items: center;
    max-width: 48%;
    flex: 1;
    background: #fff;
    border-radius: 0.6rem;
    height: 3rem;
    justify-content: center;
    font-size: 0.8rem;
    margin: 0;
    i {
      display: inline-block;
      margin-right: 0.5rem;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:nth-child(1) i{
      background-image: url('~@/assets/i_today.png');
      height: 0.8rem;
      width: 0.8rem;
    }
    &:nth-child(2) i{
      height: 1rem;
      width: 1rem;
      background-image: url('~@/assets/i_history.png');
    }

    &.sel {
      color: #377EEA;
    }
    &:nth-child(1).sel i{
      background-image: url('~@/assets/i_today_sel.png');
    }
    &:nth-child(2).sel i{
      background-image: url('~@/assets/i_history_sel.png');
    }
  }
}
.box-search {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 0.6rem;
  img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  input {
    flex: 1;
    border: 0;
    line-height: 2rem;
    height: 2rem;
  }
  .btn {
    display: inline-block;
    font-size: 0.65rem;
    width: 5rem;
    border-left: 1px solid #EFEFEF;
    text-align: center;
    margin: 0.4rem 0;
    line-height: 1.2rem;
  }
}

.box-empty {
  padding-top: 4rem;
  text-align: center;
  img {
    width: 13rem;
  }
}
.box-list {
  padding: 0 0.75rem 3rem 0.75rem;
  overflow: scroll;
  .item {
    position: relative;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0.5rem 0 0.5rem 0.5rem;

    p {
      margin: 0;
    }
    a{
      display: block;
    }
    .name {
      font-size: 0.8rem;
    }
    .time{
      color: #969799;
      font-weight: 400;
      font-size: 0.7rem;
      margin-top: 0.3rem;
    }
    .sound {
      color: #ED5A54;
      font-size: 0.5rem;
      border-top: 1px solid #EFEFEF;
      margin-top: 0.2rem;
      padding-top: 0.2rem;
      padding-left: 1rem;
      background: url('~@/assets/i_sound.png') no-repeat 0 0.2rem;
      background-size: 0.7rem auto;
    }
    div {
      strong {
        background: #CCC;
        border-radius: 12px;
        padding: 1px 5px;
        color :#000000;
      }
    }
    strong {
      position: absolute;
      right: 1rem;
      top: 1.5rem;
      transform: translateY(-50%);
      font-weight: 400;

      &.green {
        color: #11C16F;
      }
      &.yellow {
        color: #FAC15C;
      }
      &.blue {
        color: #377EEA;
      }
    }
  }
}
.el-select .el-input {
  width: 5rem;
}
.input-with-select .el-input-group__prepend, .el-input-group__append {
  background-color: #fff;
}

}
</style>
