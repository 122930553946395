<template>
  <div class="page-pwd bg-user">
    <a class="back" @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></a>
    <div class="box-title">修改密码</div>
    <div class="box-lg top-round">
    <el-form :model="lgForm" :rules="rules"
     ref="lgForm" 
     label-position="top" 
     class="box-form"
     >
        <el-form-item label="原密码" prop="oldpassword">
            <el-input type="password" 
                v-model="lgForm.oldpassword" 
                auto-complete="off" 
                placeholder="请输入原密码"
            >
            </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
            <el-input type="password"
                v-model="lgForm.password" 
                auto-complete="off" 
                placeholder="请输入新密码"
            >
            </el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPwd">
            <el-input type="password" 
                v-model="lgForm.confirmPwd" 
                auto-complete="off" 
                placeholder="再次输入新的登录密码"
            >
            </el-input>
        </el-form-item>
        <el-form-item class="box-btn">
            <el-button class="btn-ok" @click="handleSubmit" :loading="logining">确认修改</el-button>
        </el-form-item>
    </el-form>
  </div>    
</div>    
</template>

<script>
import Vue from 'vue'
import { postForm } from '@/api'

export default {
  data(){
    return {
        pwdType: 'text',
        logining: false,
        lgForm: {
            username: '',
            password: '',
        },
        rules: {
            oldpassword: [{required: true, message: '请输入', trigger: 'blur'}],
            password: [{required: true, message: '请输入', trigger: 'blur'}],
            confirmPwd: [{required: true, message: '请输入', trigger: 'blur'}]
        },
        checked: true
    }
  },
  methods: {
    handleSubmit(){
        this.$refs.lgForm.validate((valid) => {
          if (valid) {
            const {oldpassword, password, confirmPwd} = this.lgForm;
            if (password !== confirmPwd) {
              this.$message.error('新密码两次输入不一致，请重新输入。');
              return;
            }
            postForm('/wifi/admin/updatePassword', {oldpassword, password}).then(res => {
                if (res.status === 1) {
                  this.$message.success(res.msg);
                  Vue.ls.remove('ACCESS_TOKEN')
                  this.$router.push({name: 'login'});
                } else {
                  this.$message.error(res.msg);
                }
              });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    }
  },
  created() {
  }
  
}
</script>

<style lang="less">
.page-pwd {
  position: relative;
  
  .back {
    color: #fff;
    position: fixed;
    left: 0.75rem;
    top: 0.75rem;
    font-size: 1rem;
  }

  .box-title {
    font-size: 1.2rem;
  }
  .box-lg {
    padding: 1.5rem;
    background: #fff;
  }

  .lg-label img{
    width: 0.9rem;
    height: 0.9rem;
  }
  .box-btn {
    text-align: center;
    padding: 2.5rem 1rem 0.5rem 1rem;
  }
  .btn-ok {
    height: 2.3rem;
    width: 100%;
    background: #377EEA;
    border-radius: 0.5rem;
    color: #fff;
  }
}

</style>
