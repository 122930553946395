import Vue from 'vue'
import Vuex from 'vuex'
import { login, logout } from '@/api'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
    SET_USERINFO: (state, info) => {
      state.userInfo = info
    },
  },
  actions: {
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          const result = response.data || {}

          const expireTime = 180 * 24 * 60 * 60 * 1000;
          Vue.ls.set('ACCESS_TOKEN', result.token, expireTime)
          Vue.ls.set('SN', result.wifi_badge_name, expireTime)
          Vue.ls.set('ID', result.id, expireTime)
          Vue.ls.set('USER_INFO', result, expireTime)
          Vue.ls.remove('BACK_HOME')
          commit('SET_USERINFO', result)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_USERINFO', {})
          Vue.ls.remove('ACCESS_TOKEN')
          Vue.ls.remove('USER_INFO')
          Vue.ls.remove('BACK_HOME')
        })
      })
    },
  }
})
