<template>
  <div class="page-reception">
    <div id="box-nav">
      <div class="fixed">
      <a @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></a>
      <span v-if="id">补录客户档案</span>
      <span v-else>销售意向卡</span>
      </div>
    </div>
    <!-- 播放器,用于手动上传试听功能，start -->
    <div v-if="recordMode == 2 || recordMode == 1" class="box-record">
      <div class="fixed">
        <div class="info">
        <span style="color: #409eff;">试听一下~</span>
        </div>
        <div class="info">
          <span>{{detail.datetime}}</span>
          <span>{{detail.fileSize}}</span>
        </div>
        <audio-player :src="detail.url" light/>
      </div>
    </div>
    <!-- 播放器,end -->

    <div class="box-form">
      <el-form :label-position="labelPosition" label-width="80px" :model="formData" :rules="rules" ref="saleForm">
        <el-form-item v-for="(item, index) in formList" :key="index"
          :label="item.name" :prop="item.code">
          <el-input v-if="item.type == 0" v-model="formData[item.code]"
            :placeholder="'请输入'+item.name"
            ></el-input>

          <el-radio-group v-if="item.type == 1" v-model="formData[item.code]" class="group-btn">
            <el-radio v-for="(text, idx) in item.list"
              :key="idx" :label="text" border class="col2"></el-radio>
          </el-radio-group>

          <el-checkbox-group v-if="item.type == 2" v-model="formData[item.code]">
            <el-checkbox v-for="(text, idx) in item.list"
              :key="idx" :label="text"></el-checkbox>
          </el-checkbox-group>

          <el-date-picker v-if="item.type == 4"
            v-model="formData[item.code]"
            type="date"
            prefix-icon=" "
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>

          <el-time-picker v-if="item.type == 5"
            v-model="formData[item.code]"
            prefix-icon=" "
            placeholder="选择时间">
          </el-time-picker>
        </el-form-item>
      </el-form>
      <div v-if="id" class="box-btn" style="height: 3rem">
        <div class="fixed">
          <el-button type="primary" @click="updateForm">完成</el-button>
        </div>
      </div>
      <div v-else class="box-btn" style="height: 6rem">
        <div class="fixed">
        <div class="box-timer">
          <!--
          <strong v-if="started"><i class="el-icon-microphone"></i> 录音中</strong>
          <strong v-else><i class="el-icon-turn-off-microphone"></i> {{time ? '录音结束' : '未录音'}}</strong>
          -->
          <strong v-if="started">
            <img src="@/assets/i_pause.png" style="width: 1.4rem; height: 1.4rem; vertical-align: middle;"/>
            录音中</strong>
          <strong v-else> </strong>
          <span>{{formatTimer(time)}}</span>
        </div>
          <el-button v-if="started" type="danger" @click="endForm">结束录音</el-button>
          <el-button v-else type="primary" @click="startForm">开始录音</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm } from '@/api'
import { audioPlayer } from 'vue-md-player'
import 'vue-md-player/dist/vue-md-player.css'
export default {
    
  data() {
      return {
        id: '',
        time: 0,
        started: '',
        labelPosition: 'top',
        showBtn: true,
        formList: [],
        formData: {
          channel: [],
        },
        rules: {},
        reUpload: false,
        textList: [],
        detail: {},
        isEmpty: false,
        recordMode:0,
      }
  },
  components: {
    audioPlayer,
  },
  methods: {
      startTimer() {
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          if(this.time >= 60 * 60 * 60 - 1) {
            this.$message.error('时间到, 请结束录音。');
            clearInterval(this.timer);
            return;
          }
          this.time = this.time + 1;
        }, 1000);
      },
      formatTimer(t) {
        const h = Math.floor(t / 3600);
        const m = Math.floor(t % 3600 /60);
        const s = t % 60;
        const z = (v)=> {return v > 9 ? v : ('0' + v)};
        return z(h) + ' : ' + z(m) + ' : ' + z(s);
      },
      updateForm() {
        this.$refs.saleForm.validate((valid) => {
          if (valid) {
            postForm('/wifi/home/supplementUpdate', {
              ...formatData(this.formData),
              id: this.id,
            }).then(res => {
                clearInterval(this.timer);
                if (res.status === 1) {
                  this.started = false;
                  this.showBtn = false;
                  this.$message.success(res.msg);
                  this.$router.push({name: 'index'});
                } else {
                  this.$message.error(res.msg);
                }
              });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      endForm() {
        this.$refs.saleForm.validate((valid) => {
          if (valid) {
            postForm('/wifi/record/stop', {
              ...formatData(this.formData),
              task_num: this.taskNum || '',
            }).then(res => {
                clearInterval(this.timer);
                if (res.status === 1) {
                  Vue.ls.remove('BUSINESS');
                  this.started = false;
                  this.showBtn = false;
                  this.$message.success(res.data);
                  this.$router.push({name: 'index'});
                } else {
                  this.$message.error(res.msg);
                }
              });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      startForm() {
        this.startRecord()
      },

      startRecord() {
        postForm('/wifi/record/start', {business: this.business}).then(res => {
            if (res.status === 1) {
              Vue.ls.set('BUSINESS', this.business);
              this.started = true;
              this.taskNum = res.data.task_num;
              this.startTimer();
            } else {
              this.$message.error(res.msg);
            }
          });
      },

      checkState() {
        postForm('/wifi/record/getRecordStatus').then(res => {
          if (res.status === 1) {
            const {status, start_time, task_num} = res.data || {};
            this.taskNum = task_num;
            if (status === 'start') {
              this.started = true;
              this.time = Math.min(parseInt((new Date().getTime() - start_time) / 1000), 60*60*60-1);
              this.startTimer();
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      recount() {
        if (document.visibilityState === 'visible') {
          this.checkState();
        }
      },
    },
  destroyed() {
    document.removeEventListener('visibilitychange', this.recount);
    clearInterval(this.timer);
  },
  created() {
    const {type, id, status, task_num} = this.$router.currentRoute.query || {};
    this.id = id;
    this.business = type;
    this.taskNum = task_num;
    postForm('/wifi/home/cardlist', {business: type}).then(res => {
      if (res.status === 1) {
        this.formList = res.data || [];

        const msg = {0: '请输入', 1: '请选择'};
        this.rules = [...this.formList].reduce((s,c) => {
          return {...s, [c.code]: { required: c.is_must == 1, message: msg[c.type] || '请选填' }}
        }, {});
      } else {
        this.$message.error(res.msg);
      }
    });
    postForm('/wifi/home/detailRecord', {id: this.id}).then(res => {
      if (res.status === 1) {
        const {RecordText, reUpload, ...rest} = res.data.audio;
        this.recordMode = res.data.recordMode;
        this.detail = rest;
        this.reUpload = reUpload;
        this.textList = RecordText || [];
        this.isEmpty = !this.textList.length;
      } else {
        this.$message.error(res.msg);
      }
    });
    if (id) {
      return;
    }

    if (status === 'start') {
      this.checkState();
    } else {
      this.startRecord()
    }
    document.addEventListener('visibilitychange', this.recount);
  },

  mounted() {
      const initData = {
        0: '',
        1: null,
        2: [],
        4: '',
        5: '',
      };
      this.formList.map(o => {
        this.formData[o.code] = initData[o.type]
      });
  },
}

function formatData(data) {
  const keys = Object.keys(data);
    console.log(keys);
  const obj = {};
  keys.map(o => {
    if (Array.isArray(data[o])) {
      obj[o] = data[o].join(',');
    } else {
      obj[o] = data[o];
    }
  });
  return obj;
}

</script>

<style lang="less">
.page-reception {
  .box-form {
    padding: 0 0.75rem;
  }

#box-nav {
  height: 2.5rem;

  a{
    position: absolute;
    left: 0.75rem;
  }
  .el-icon-arrow-left {
    font-size: 0.8rem;
    color: #000;
  }
  .fixed{
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    line-height: 2rem;
    font-size: 0.8rem;
    text-align: center;
    z-index: 1000;
    background: #fff;
  }
}
.el-form--label-top .el-form-item__label {
  padding: 0;
  font-weight: bold;
  font-size: 0.75rem;
}
.el-radio-button__inner, .el-radio-group {
  line-height: 1.5rem;
}
  .box-record {
    height: 6.5rem;
    .fixed {
      padding-bottom: 1rem;
      padding-top: 0.5rem;
      background: #fff;
      left: 0;
      right: 0;
      z-index: 1000;

      .info {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        font-size: 0.7rem;
        font-weight: 500;
      }
      .vuemdplayer {
        min-height: 60px;
        border-bottom: 2px solid #409eff;
        .layout {
          position: relative;
          display: block;
        }
        .player-btn {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          background: #377EEA;
          svg {
            fill: #fff;
          }
        }
        .times {
          padding: 0;
          opacity: 0.36;
          display: flex;
          justify-content: space-between;

          span:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }


.box-btn {
  .fixed {
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    button{
      width: 100%;
      border-radius: 0;
      line-height: 2.5rem;
      padding: 0;
      font-size: 0.8rem;
      border: 0;
    }
  }
  .box-timer {
      margin: 0 0.75rem;
      padding: 0.5rem 0 2rem 0;
      display: flex;
      justify-content: space-between;
      font-size: 0.7rem;
      font-weight: 500;
      background: #fff url('~@/assets/sound.png') 0 80% no-repeat;
      background-size: 100% 1.5rem;

      span {
        display: inline-block;
        line-height: 1.5;
        border-radius: 20px;
        color: #000;
      }
  }
}

}
</style>
