<template>
  <div class="box-text">
    <div class="sec box-chat">
      <el-empty v-if="isEmpty" description="暂无记录"></el-empty>
      <p v-else v-for="(item,index) in textList" :key="index" :class="item.role">
        <strong>{{item.role}}</strong>
        <span>{{item.message}}</span>
      </p>
    </div>
    <el-button v-if="reUpload"  type="primary"
          class="upload" @click="onReupload">重新上传</el-button>
    <div class="box-record">
      <div class="fixed">
        <div class="info">
          <span>{{detail.datetime}}</span>
          <span>{{detail.fileSize}}</span>
        </div>
        <audio-player :src="detail.url" light/>
      </div>
    </div>
  </div>
</template>

<script>
import { postForm } from '@/api'
import { audioPlayer } from 'vue-md-player'
import 'vue-md-player/dist/vue-md-player.css'

export default {
  data() {
      return {
        reUpload: false,
        textList: [],
        detail: {},
        isEmpty: false,
      }
  },
  components: {
    audioPlayer,
  },
  methods: {
    onReupload() {
      const {taskNum, sn} = this.detail;
      postForm('/wifi/record/reUpload', {task_num: taskNum, sn}).then(res => {
          if (res.status === 1) {
            this.reUpload = 0;
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    loadData() {
      postForm('/wifi/home/detailRecord', {id: this.id}).then(res => {
          if (res.status === 1) {
            const {RecordText, reUpload, ...rest} = res.data.audio;
            this.detail = rest;
            this.reUpload = reUpload;
            this.textList = RecordText || [];
            this.isEmpty = !this.textList.length;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
  },
  created() {
    const {id} = this.$router.currentRoute.query || {};
    this.id = id;
    this.loadData();
  }
  
}
</script>

<style lang="less">

.box-text {
  position: relative;

  .upload{
    margin: 1rem 10%;
    width: 80%;
    line-height: 2.5rem;
    padding: 0;
    font-size: 0.8rem;
    border: 0;
  }
  
  .box-chat {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    >p {
      display: flex;

      strong {
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
        line-height: 1rem;
        border-radius: 50%;
        background: #DEE4FE;
        text-align: center;
      }
      span {
        background: #eee;
        padding: 0.1rem 0.5rem;
        border-radius: 0.2rem;
      }
      &.A {
        strong {
          margin-right: 0.5rem;
        }
      }

      &.B {
        flex-direction: row-reverse;
        strong {
          margin-left: 0.5rem;
        }
        span {
          background: #D3DCFB;
        }
      }
    }
  }
  .box-record {
    height: 5.5rem;
    .fixed {
      padding-bottom: 1rem;
      padding-top: 0.5rem;
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;

      .info {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        font-size: 0.7rem;
        font-weight: 500;
      }
      .vuemdplayer {
        min-height: 60px;

        .layout {
          position: relative;
          display: block;
        }
        .player-btn {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          background: #377EEA;
          svg {
            fill: #fff;
          }
        }
        .times {
          padding: 0;
          opacity: 0.36;
          display: flex;
          justify-content: space-between;

          span:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
</style>
